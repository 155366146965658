import axios from 'axios'

const http = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API
});

http.interceptors.request.use((config) => {
    const usuario = JSON.parse(sessionStorage.getItem('usuario-token'));

    if (usuario && usuario.token){
      config.headers.Authorization = `Bearer ${usuario.token}`;
    }

    return config
  })


export default http;
